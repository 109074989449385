a {
  text-decoration: none;
  font-weight: 600;
  color: #2459a8;
}

#okta-sign-in.auth-container.main-container {
  border: 0px #fff !important;
}

#okta-sign-in .siw-main-view .infobox-error {
  background-color: #cc3333;
  color: #fff;
  font-size: 16px;
}

#okta-sign-in .warning-16::before {
  color: #2e2925;
}

#okta-sign-in .siw-main-view .infobox-warning {
  background-color: #aed8ef;
  color: #2e2925;
  font-size: 16px;
}

#okta-sign-in .help-wrap .infobox.infobox-warning::before,
#okta-sign-in .infobox-warning::before,
#okta-sign-in .infobox.infobox-subtle.infobox-warning::before {
  background-color: #aed8ef;
}

#okta-sign-in .siw-main-view .clearfix::after,
#okta-sign-in .siw-main-view .clearfix::before {
  height: 100%;
}

#okta-sign-in .infobox,
#okta-sign-in .infobox-md {
  border-radius: 0px;
  border: 0px;
}

#okta-sign-in .infobox>.icon {
  line-height: 28px;
}

#okta-sign-in .help-wrap .infobox.infobox-error::before,
#okta-sign-in .infobox-error::before,
#okta-sign-in .infobox.infobox-subtle.infobox-error::before {
  background-color: #cc3333;
}

#okta-sign-in .infobox-compact::before,
#okta-sign-in .infobox-md::before,
#okta-sign-in .infobox::before {
  border-radius: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
}

#authenticator-description--text no-translate {
  font-size: 18px;
}

#okta-sign-in.auth-container .okta-sign-in-header {
  background-color: #fff !important;
  border: 0px #fff !important;
  padding-bottom: 0px !important;
}

.button-primary {
  background: #656363 !important;
  /* rgb(26, 137, 39) !important; /*  */
  border-color: #656363 !important;
  /* rgb(26, 137, 39) !important; /* #656363 !important; */
  color: #ffffff !important;
}

.login-bg-image {
  background-color: white !important;
}

#okta-sign-in.auth-container h2 {
  font-size: 20px;
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  background-color: #fff;
}

#okta-sign-in .o-form .custom-checkbox label,
#okta-sign-in .o-form .custom-checkbox label:hover {
  font-size: 18px;
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  color: #171717;
}

.chzn-container-active {
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
}

.chzn-container .chzn-results {
  position: static;
}

#okta-sign-in .auth-org-logo {
  display: block;
  margin: 30px auto !important;
  max-width: 200px;
  max-height: 90px !important;
}
/* //Test */
#okta-sign-in .auth-header {
  border-bottom: 1px solid #ddd;
  padding: 0px 90px 75px !important;
  position: relative;
  -moz-transition: padding-bottom .4s;
  -webkit-transition: padding-bottom .4s;
  transition: padding-bottom .4s;
  z-index: 10;
}
.auth-header:after {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  visibility: visible;
  /* content: "Log In to Online Banking"; */
  color: #2459a8;
  font-size: 24px !important;
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  padding-top: 10px;
  /* margin-right: 65px; */
  /*margin-left: -220px; */
}

#okta-sign-in .auth-content {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 700px;
}

#okta-sign-in.no-beacon .auth-content {
  padding-top: 0px;
}

.okta-sign-in-header .auth-header {
  padding-bottom: 0px;
}

#okta-sign-in {
  background-color: #fff;
}

#auth-content-inner {
  background-color: #656363;
  padding: 10px;
}

#okta-sign-in.no-beacon .auth-content {
  background-color: #fff !important;
}

#okta-sign-in .auth-content .auth-content-inner {
  /*   padding-top: 10px;
          padding-bottom: 25px;
          padding-left: 25px;
          padding-right: 20px; */
}

.sign-in-with-device-option {
  display: none;
}

#okta-sign-in.auth-container .button button {
  visibility: hidden;
}

/*    #okta-sign-in.auth-container .button:after{
          visibility: visible;
          content:'' !important;
          color: #2459a8 ;
      }*/

#okta-sign-in.auth-container .button-primary {
  background-color: #656363;
  /*rgb(26, 137, 39);  */
}

#okta-sign-in .auth-beacon {
  display: none !important;
  background-clip: content-box;
  box-shadow: none !important;
}

#okta-sign-in.auth-container.main-container {
  box-shadow: none !important;
  /*padding-bottom: 100px;*/
}

#okta-sign-in.auth-container .o-form-explain {
  margin-top: 0px;
}

#okta-sign-in .beacon-container {
  display: none !important;
}

#okta-sign-in-header.auth-header {
  background-color: #ffffff !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

#okta-sign-in.no-beacon .auth-header {
  padding-top: 0px;
}



#okta-sign-in .o-form-head {
  font-size: 20px;
  /* padding: 13px 65px; */
}

#okta-sign-in {
  width: 600px;
  background-color: #fff !important;
  margin-top: 0px;
}

#okta-sign-in .siw-main-view .infobox a {
  color: #2459a8;
  font-weight: 700 !important;
}

#okta-sign-in.auth-container .link,
#okta-sign-in.auth-container .link:active,
#okta-sign-in.auth-container .link:hover,
#okta-sign-in.auth-container .link:link,
#okta-sign-in.auth-container .link:visited {
  color: #2459a8;
  font-weight: 700 !important;
  font-size: 17px;
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
}

#okta-sign-in.auth-container input[type="button"],
#okta-sign-in.auth-container input[type="submit"] {
  font-size: 17px;
  font-weight: 700 !important;
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
}

#okta-sign-in .custom-checkbox label,
#okta-sign-in .custom-radio label,
#okta-sign-in .o-form .custom-checkbox label:hover,
#okta-sign-in .o-form .custom-checkbox label:focus,
#okta-sign-in .o-form .custom-checkbox label.checked {
  /*background-position: -15px -8px;*/
}

#okta-sign-in .siw-main-view .custom-checkbox label {
  line-height: 16px;
}

.button-primary:hover {
  background-color: green !important;
}

.link js-forgot-password a {
  text-align: right;
}


#okta-sign-in .o-form .input-fix .password-toggle .eyeicon:focus {
  /* outline: -webkit-focus-ring-color auto 2px !important; */
}


#okta-form-label .o-form-label {
  font-size: 18px;
}

.o-form-fieldset-container {
  background-color: #F6F5F5 !important;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

#o-form-fieldset-container div {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0px;
  padding-right: 0px;
}

#siw-main-body div {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0px;
  padding-right: 0px;
}

#okta-sign-in .o-form-fieldset {
  padding-bottom: 0px;
}

#okta-sign-in .siw-main-view .siw-main-body .o-form-content .o-form-label {
  padding-bottom: 15px;
}

#okta-sign-in .o-form .o-form-fieldset:last-child {
  margin-bottom: 0px;
}

#okta-sign-in .o-form-button-bar {
  background-color: #F6F5F5 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

#okta-sign-in .o-form-theme {
  padding-bottom: 0px;
}

#okta-sign-in .siw-main-footer .auth-footer {
  width: 100%;
  background-color:#F6F5F5  !important;
  writing-mode: horizontal-tb;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  align-content: end;
}

#okta-sign-in .siw-main-footer .auth-footer .link {
  padding-top: 15px !important;
  padding-bottom: 8px !important;
  padding-left: 26px !important;
  padding-right: 26px !important;
}
#okta-sign-in .authenticator-verify-list .authenticator-description>div {
  margin: 0px 0px !important;
}

.auth-footer {}

.siw-main-body {
  padding: 10px;
}

.js-forgot-password {
  grid-column: 2 / 2;
  align-self: flex-end;
  margin-left: auto !important;
}

.js-unlock {
  grid-column: 2 / 3;
  padding: 30px;
  padding-top: 0px !important;
  align-self: flex-end;
  margin-left: auto !important;
}

.js-help {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: start;
  padding:20px 20px !important;
}

.js-switchAuthenticator {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  align-self: start;
  padding: 30px;
}

.js-cancel {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  align-self: flex-end;
  margin-left: auto !important;
}

.list-content {
  background-color: #F6F5F5 !important;
}

#okta-sign-in .authenticator-row {
  margin-bottom: 0px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

#okta-sign-in .authenticator-verify-list .authenticator-description {
  width: 100%;
  writing-mode: horizontal-tb;
  display: inline-grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
  align-content: end;
}

#okta-sign-in .authenticator-verify-list .authenticator-description .authenticator-label {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: start;
  font-size: 18px;
}

.okta-form-subtitle:after {
  /*content: 'Please contact our support team if you did not receive a text. Customer Support: 1-800-448-8812 M-F: 7am - 7pm Sat: 8am - 2pm (Central Time)';*/
}

.list-content {
  padding-top: 10px !important;
}

#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
  color: #6b6b70;
}

#okta-sign-in .o-form .o-form-label,
#okta-sign-in .o-form input,
#okta-sign-in .o-form label,
#okta-sign-in .o-form textarea {
  font-size: 18px;
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  color: #2e2925;
}

#okta-sign-in .authenticator-row .authenticator-button .button {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15),
    inset 0 1px 0 0 hsla(0, 0%, 100%, 0.1);
  -webkit-appearance: none;
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  padding: 0 35px;
  background: #656363 !important;
  border-color: #656363 !important;
  color: #ffffff !important;
  width: auto;
  font-size: 16px;
  grid-column: 2 / 2;
  align-self: flex-start!important;
  /* margin-left: auto !important; */
  text-align: center;
}

/* ADA Testing code  */
#okta-sign-in .authenticator-row .authenticator-button .button:hover,
#okta-sign-in .authenticator-row .authenticator-button .button:focus {
  outline: 2px solid #000 !important;
  background-color: #1a8927 !important;
}

/* General link styles */
#okta-sign-in.auth-container .link,
#okta-sign-in.auth-container .link:active,
#okta-sign-in.auth-container .link:link,
#okta-sign-in.auth-container .link:visited {
    color: #2459a8; 
    font-family: Roboto, Arial Narrow, Arial, sans-serif;
    font-size: 17px; 
    font-weight: 700 !important;
}

/* Hover state */
/* #okta-sign-in.auth-container .link:hover {
    color: #123456; 
} */
#okta-sign-in .password-toggle .eyeicon:focus-visible {
  border: 2px solid #000000 !important; /*contrast 6.3:1*/
}
/* Focus state */
#okta-sign-in.auth-container .link:focus-visible {
  border: 2px solid #000000!important; 
}
/* Resend link focus style */
#okta-login-container .resend-link:focus-visible {
  border: 2px solid #000000!important;
}
/* Checkbox focus style 050505 */
.custom-checkbox input[type="checkbox"]:focus-visible {
  outline: 2px solid #171717 !important; 
}
/* Active state */
#okta-sign-in.auth-container .link:active {
    /* color: #123456;  */
    /* border: 2px solid black;  */
}
/* .phone-authenticator-challenge__link ADA  */
.phone-authenticator-challenge__link:focus-visible {
  outline: none;
  color: #ffffff !important;
  background-color: #1a8927 !important;
  /* box-shadow: 0 0 0 2px #D6FFD6!important; Contrast Ratio 3.42:1  */
}
/* .phone-authenticator-challenge__link:active {
  box-shadow: 0 0 0 2px #D6FFD6!important; 
}
.phone-authenticator-challenge__link:hover {
  box-shadow: 0 0 0 2px #D6FFD6!important; 
} */
/* ADA Testing code  */
#okta-sign-in .authenticator-enroll-list .list-content .authenticator-list-title {
  padding-left: 30px;
  padding-bottom: 20px;
}

#okta-sign-in.auth-container.main-container .primary-auth .password-with-toggle:focus {
  outline: -webkit-focus-ring-color auto 2px !important;
  /* box-shadow: 0 0 0 2px #D6FFD6; /* Contrast Ratio 3.42:1 */
}

/* // */
#okta-sign-in .custom-checkbox input, #okta-sign-in .custom-radio input {
  filter: Alpha(Opacity = 0);
  left: 0;
  margin: 0;
  opacity: 1;
  position: absolute;
  top: 7px;
  width: 18px;
  height: 18px;
}
/* test */
#okta-sign-in .siw-main-view .custom-checkbox label {
  line-height: 16px;
  font-weight: 300;
}

/* #okta-sign-in .siw-main-view .siw-main-body span.strong{
      font-size: 15px;
  } */
#okta-sign-in div {
  font-size: 16px;
}

#okta-sign-in.auth-container .okta-form-input-field input {
  font-family: 'Roboto';
  font-weight: 400;
}

#okta-sign-in .siw-main-footer .auth-footer .link {
  /* margin-right: 5px; */

}

#okta-sign-in .siw-main-view .siw-main-body span.strong {
  font-size: 16px;
}

#okta-sign-in .custom-radio {
  display: none;
}

#okta-sign-in .infobox>.icon {
  left: 20px;
  position: absolute;
  top: 12px;
}

#okta-sign-in div {
  /* font-size: 16px; */
}

.phone-authenticator-challenge__link {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  /* background-color: white; */
  /* padding: 0px; */
  /* width: 96% !important; */
  border: 1px solid #1a8927 !important;
  color: #1a8927 !important;
  /* float: left; */
  font-size: 17px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 !important;
  background-color: #ffffff !important;
  /* height: 40px; */
  border-radius: 3px;
}

.phone-authenticator-challenge__link:hover,
.phone-authenticator-challenge__link:focus{
  /*color: var(--bs-btn-hover-color) !important;
  background-color: var(--bs-btn-hover-bg) !important;*/
  color: #ffffff !important;
  background-color: #1a8927 !important;
  border-color: var(--bs-btn-hover-border-color) !important;
}

.unlockmfa {
  text-align: center !important;
  margin-bottom: 10px !important;
}

#okta-sign-in.auth-container h2 {
  margin-bottom: 0px;
}

#okta-sign-in a {
  font-size: 16px !important;
}

#noMatchError {
  display: block !important;
}

#noMatchErrorSpan:before {
  color: #e34843;
  content: "\e055";
}

.button-primary:disabled {
  opacity: 0.6;
}

#okta-sign-in .authenticator-row .authenticator-button .button:disabled {
  opacity: 0.6;
}

#okta-sign-in.auth-container h2 {
  padding: 0 50px 20px !important;
}
.o-form-error-container{
  background-color: #F6F5F5 !important;
}
#okta-sign-in .siw-main-view .okta-form-subtitle {
  /* margin: 25px 0px 0px; */
  /* text-align: left !important; */
  padding: 20px 13px 5px !important;
  margin: 0px 0 0px !important;
  background-color: #F6F5F5;
}
#okta-sign-in .enter-auth-code-instead-link {
  display: block;
  margin: 25px auto;
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: auto;
}

#okta-sign-in .siw-main-view .siw-main-body .ion-messages-container{
  margin-bottom: 0rem !important;
  text-align: center !important;
  padding: 2px !important;
  color:  #CC3333 !important;
}
.no-translate{
  /* margin-left: -1px !important; */
}
#okta-sign-in .siw-main-view .custom-checkbox {
  margin-bottom: 10px !important;
}
.authenticator-usage-text{
  display: none !important;
}
.js-cancel{
  /* padding-bottom: 10px !important; */
  margin-bottom: -3px !important;
  /* border: 1px solid red !important; */
}