#footer {
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #6e6e78;
}

footer {
  font-family: "AppleSDGothicNeo-Light", "Roboto", "Arial Narrow", Arial, sans-serif;
  font-size: 14.1px;
  font-weight: 400;
  color: #586977;
  grid-area: footer;
  overflow: hidden;
}

.footertext {
  padding-left: 30px;
}

footer hr {
  color: #eeeeee;
  background-color: #eeeeee;
  height: 2px;
  border: 0px;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  #footer {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .footertext {
    padding-left: 10px; /* Adjust padding for smaller screens */
  }
}

/* Additional Media Queries for Different Screen Sizes */
@media only screen and (max-width: 480px) {
  #footer {
    font-size: 10px; /* Further adjust font size for even smaller screens */
  }

  .footertext {
    padding-left: 5px; /* Further adjust padding for even smaller screens */
  }
}
