/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
  font-family: 'Roboto';

  /*src: url('https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf') format('truetype');*/
  src: url('roboto.ttf') format('truetype');
}

/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');*/
@import url('roboto.css');

/*
#okta-sign-in .siw-main-footer .auth-footer .link:focus {

  outline: -webkit-focus-ring-color auto 2px !important;
  outline-offset: -7px;
}

span:focus {
  outline: -webkit-focus-ring-color auto 2px !important;
}
*/

#okta-sign-in a,
#okta-sign-in abbr,
#okta-sign-in acronym,
#okta-sign-in address,
#okta-sign-in applet,
#okta-sign-in b,
#okta-sign-in big,
#okta-sign-in blockquote,
#okta-sign-in body,
#okta-sign-in caption,
#okta-sign-in center,
#okta-sign-in cite,
#okta-sign-in code,
#okta-sign-in dd,
#okta-sign-in del,
#okta-sign-in dfn,
#okta-sign-in div,
#okta-sign-in dl,
#okta-sign-in dt,
#okta-sign-in em,
#okta-sign-in fieldset,
#okta-sign-in form,
#okta-sign-in h1,
#okta-sign-in h2,
#okta-sign-in h3,
#okta-sign-in h4,
#okta-sign-in h5,
#okta-sign-in h6,
#okta-sign-in html,
#okta-sign-in i,
#okta-sign-in iframe,
#okta-sign-in img,
#okta-sign-in ins,
#okta-sign-in kbd,
#okta-sign-in label,
#okta-sign-in legend,
#okta-sign-in li,
#okta-sign-in object,
#okta-sign-in ol,
#okta-sign-in p,
#okta-sign-in pre,
#okta-sign-in q,
#okta-sign-in s,
#okta-sign-in samp,
#okta-sign-in small,
#okta-sign-in span,
#okta-sign-in strike,
#okta-sign-in strong,
#okta-sign-in sub,
#okta-sign-in sup,
#okta-sign-in table,
#okta-sign-in tbody,
#okta-sign-in td,
#okta-sign-in tfoot,
#okta-sign-in th,
#okta-sign-in thead,
#okta-sign-in tr,
#okta-sign-in tt,
#okta-sign-in u,
#okta-sign-in ul,
#okta-sign-in var {
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-size: 16px;
  /* color: #6B6B70; */
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: "main" "footer";
  grid-template-rows: 1fr 80px;
  font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  -moz-text-size-adjust: none;
}

main {
  grid-area: main;
}


.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
}
.app {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* height: 100vh; */
}


.loading {
  background-image: url('./hancock-whitney-logo.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
  width: 40vw;
  opacity: 0.5;
  margin: 10% auto !important;
}

/* Default styling */
.authenticator-row {
  display: flex !important;
  align-items: center !important;
  /* margin-bottom: 20px !important; */
}
.authenticator-icon-container {
  flex-shrink: 0 !important;
  margin-right: 10px !important;
}
.authenticator-description {
  flex-grow: 1 !important;
}
.authenticator-description div {
  display: flex !important;
  flex-direction: column !important;
}
.authenticator-button {
  margin-top: 10px !important;
}
/* for footer */
/* .footer{
  position: fixed !important;
    /* left: 0; */
    /* bottom: 0 !important;
    width: 100% !important; */
    /* background-color: red; */
    /* color: white; */
    /* /* text-align: center; */
/* } */
/* @media (max-height: 600px) {
  .footer {
      position: absolute !important;
      background-color: red;
  }
}  */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; 
}

@media (max-height: 750px) {
  .footer {
      position: static !important;
  }
}
/* Mobile responsiveness for screens 600px and below */
@media screen and (max-width: 900px) {
  .authenticator-row {
      flex-direction: column !important;
  }
  .authenticator-icon-container {
      margin-bottom: 10px !important;
      margin-right: 0 !important; /* Remove right margin for smaller screens */
  }
  .authenticator-description {
      text-align: center !important; /* Center text for smaller screens */
  }
  .authenticator-button {
      width: 100% !important; /* Make buttons full width */
  }
/* // */
  
#okta-sign-in .authenticator-verify-list .authenticator-description{
  display: block !important;
}
.authenticator-description--text {
  margin-left: 8px !important;
}
#okta-sign-in .authenticator-verify-list .authenticator-description .authenticator-label{
  /* margin-left: 20px !important; */
}
#okta-sign-in .authenticator-row{
  margin-bottom: 0px !important;
}
#okta-sign-in.auth-container h2 {
  padding: 0 0px 20px !important;
}
#okta-sign-in .siw-main-view .okta-form-subtitle {
  /* display: none !important; */
}
#okta-sign-in .authenticator-row .authenticator-button .button {
  align-self: auto;
  -webkit-appearance: none;
  background: #656363!important;
  border: 1px solid;
  border-color: initial;
  border-color: #656363!important;
  border-radius: 3px;
  box-shadow: 0 1px 0 #00000026, inset 0 1px 0 0 #ffffff1a;
  box-sizing: border-box;
  color: #fff!important;
  /* display: -webkit-inline-box; */
  font-size: 16px;
  grid-column: 2/2;
  /* margin-left: 41px; */
  padding: 0 30px;
  text-align: center !important;
  width: initial !important;
  margin: 0 auto !important;
}
#okta-sign-in .authenticator-row .authenticator-icon-container {
  align-self: center;
  margin-right: 20px;
}
.button.button-primary {
  white-space: normal; /* Allow text to wrap */
  /* height: 75px ; */
}
#okta-sign-in .siw-main-footer .auth-footer{
  display: inline-flex !important;
}


#okta-sign-in .phone-authenticator-challenge__link {
  display: block;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
  padding: 0 25px;
}
.button.button-primary[value="Text a 6-digit code to your phone"]{
  -webkit-appearance: none;
  border: 1px solid;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  line-height: 20px !important;
  padding: 0 15px;
}


.button.button-primary[value="Text a 6-digit code to your phone"]{
  display: block;
  height: 70px !important;
  width: 100%;

}
#okta-sign-in .siw-main-footer .auth-footer .link{
  display: block !important;
  margin: 0 auto !important;
}
.js-help{
  padding-top: 0% !important;
}
#okta-sign-in.auth-container .auth-content {
  margin: 0 auto;
  max-width: 370px !important;
}
#okta-sign-in .auth-content{
  /* padding: 60px 25px 20px !important; */
}

#okta-sign-in .siw-main-view .siw-main-body span.strong{
  display: inline-block !important;
}
#okta-sign-in .siw-main-view .okta-form-subtitle{
  display: block !important;
}
#okta-sign-in.auth-container .auth-content {
  margin: 0 auto !important;
  max-width: 400px !important;
}
#okta-sign-in .authenticator-verify-list .authenticator-description .authenticator-label {
  align-self: center !important;
  font-size: 18px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.no-translate{ 
/* display: none !important; */
}
}
